import Events from './eventData';
import Modal from './modal';

function eventTitle(eventData) {
  const notEmpty = arr => arr
    .map(item => ((typeof item === 'string') ? item.trim() : item))
    .filter(item => !!item);

  const online = eventData.isOnline ? 'Online ' : '';

  return eventData.name
    || `${online}IDM event in ${notEmpty([eventData.city, eventData.region, eventData.country]).join(', ')}`;
}

function eventDetails(eventData) {
  const $output = $('<div class="event-details"></div>');
  const $row = $('<div class="row"></div>');
  $row.appendTo($output);

  const renderField = (name, value) => {
    return $('<div class="event-details-field"></div>')
      .append($('<div class="event-details-field-label"></div>').html(name))
      .append($('<div class="event-details-field-value"></div>').html(value));
  };

  $row
    .append($('<div class="col-md">')
      .append([
        ['Date', Events.renderDate(eventData)],
        ['Time', eventData.time],
        ['Address', Events.renderAddress(eventData)],
        ['Website', Events.renderWebsite(eventData)],
      ]
        .filter(([, v]) => v !== undefined && v.length !== 0)
        .map(([k, v]) => renderField(k, v)))
      .append(Events.renderOnlineNotice(eventData))
    );

  if (eventData.description) {
    $row.append($('<div class="col-md"></div>')
      .append($('<div class="event-details-description"></div>')
        .html(Events.renderDescription(eventData))));
  }

  return $output;
}

function showEventDetails(modal, eventSet, eventId) {
  modal.showLoader();
  Events.load(eventSet)
    .then((events) => {
      const eventData = events.find(event => (event.id === eventId));
      if (eventData) {
        modal.show(eventTitle(eventData), eventDetails(eventData));
      } else {
        modal.show('Error loading event', 'The selected event could not be found');
      }
    })
    .catch((err) => {
      modal.show('Error loading event', 'The selected event could not be loaded');
      console.error(err);
    });
}

if ($('body').hasClass('withEventDetails')) {
  const modal = new Modal();
  $('body').append(modal.getElement());

  const updateEventDetailsModal = () => {
    const matches = window.location.hash.match(/^#event-details\/([a-zA-Z0-9-_]+)\/(\d+)$/);
    if (matches && matches.length === 3) {
      const eventSet = matches[1];
      const eventId = matches[2];
      showEventDetails(modal, eventSet, Number.parseInt(eventId, 10));
    } else {
      modal.hide();
    }
  };

  $(modal.getElement()).on('hidden.bs.modal', () => {
    if (window.history.pushState) {
      window.history.pushState(null, null, '#');
    } else {
      window.location.hash = '';
    }
  });

  $(window).on('hashchange', () => {
    updateEventDetailsModal();
  });
  updateEventDetailsModal();
}

$('[data-field="event-count-legend"]').each((i, container) => {
  const set = $(container).attr('data-events-set');
  if (!set || !set.match(/^[a-zA-Z0-9-_]+$/)) {
    return;
  }
  Events.load(set).then((events) => {
    $(container).html(`over ${events.length}`);
  });
});
