/* eslint-disable import/no-extraneous-dependencies */
import 'whatwg-fetch'; // Polyfill
import config from './config';

const loadPromise = { };

/**
 * Fetches the list of events from the server
 *
 * @return Promise<any>
 */
function load(eventSet) {
  if (loadPromise[eventSet] === undefined) {
    const eventsFileUrl = config.staticFile(`data/events-${eventSet}.json`);
    loadPromise[eventSet] = window.fetch(eventsFileUrl, {
      cache: 'no-cache',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${eventsFileUrl} file not found`);
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => data.events);
  }

  return loadPromise[eventSet];
}

const notEmpty = (arr) => arr
  .map((item) => ((typeof item === 'string') ? item.trim() : item))
  .filter((item) => !!item);

const formatDate = (s) => {
  const parts = s.split('-');
  const d = new Date(parts[0], Number.parseInt(parts[1], 10) - 1, parts[2]);
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'];

  return `${months[d.getMonth()]} ${d.getDate()}`;
};

const getYear = (s) => {
  const parts = s.split('-');
  return parts[0];
};

function renderDate(eventData) {
  const range = [eventData.dateStart, eventData.dateEnd]
    .filter((s) => typeof s === 'string' && s.length)
    .map((s) => formatDate(s))
    .join(' to ');

  return `${range}, ${getYear(eventData.dateStart)}`;
}

function renderAddress(eventData) {
  return notEmpty([
    eventData.venue,
    eventData.address && eventData.address.replace(/\n/g, '<br>'),
    notEmpty([eventData.city, eventData.region, eventData.country]).join(', '),
  ]).join('<br>');
}

function renderWebsite(eventData) {
  if (eventData.website) {
    const link = $('<a></a>')
      .addClass('link-external')
      .attr('rel', 'noopener nofollow')
      .attr('target', '_blank')
      .attr('href', eventData.website);
    link.html(`[${link[0].hostname}]`);

    return link;
  }
  return '';
}

function renderDescription(eventData) {
  return eventData.description && eventData.description.replace(/\n/g, '<br>');
}

function renderOnlineNotice(eventData) {
  if (!eventData.isOnline) {
    return null;
  }

  const $notice = $('<div class="online-event-notice">')
    .text('You can join this event online.');

  if (eventData.website) {
    $notice
      .append('<br>')
      .append($('<a>')
        .attr('href', eventData.website)
        .text('Check the website for details'));
  }

  return $notice;
}

export default {
  load,
  renderDate,
  renderAddress,
  renderWebsite,
  renderDescription,
  renderOnlineNotice,
};
