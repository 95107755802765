import idmAlert from './idm-alert';

$('[data-component=geolocation]').each((i, element) => {
  let $loader = null;

  const showLoader = () => {
    if ($loader === null) {
      $loader = $('<div class="lds-ellipsis lds-ellipsis-btn-lg"><div></div><div></div><div></div></div>');
      $(element).after($loader);
    }
  };

  const hideLoader = () => {
    if ($loader !== null) {
      $loader.remove();
      $loader = null;
    }
  };

  $(element).on('click', () => {
    $(element).prop('disabled', true);
    showLoader();
    const $targets = $(element).attr('data-targets')
      .split(' ')
      .map((id) => $(`#${id}`));

    if (!navigator.geolocation) {
      idmAlert('Geolocation is not supported by your browser');
      $(element).prop('disabled', false);
      hideLoader();
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          $targets.forEach(($target) => {
            if ($target.data('component') && $target.data('component').setGeolocation) {
              $target.data('component').setGeolocation(position);
            }
          });
          $(element).prop('disabled', false);
          hideLoader();
        },
        () => {
          $(element).prop('disabled', false);
          hideLoader();
          idmAlert('We were unable to retrieve your location.<br>Make sure to authorize the use of location data in your browser.');
        }
      );
    }
  });
});
