const config = require('../../config.json');
const Countdown = require('countdown');
import parseISO from 'date-fns/parseISO';

// Countdown
$('[data-component=countdown]').each((i, countdownElement) => {
  const goal = new Date(Number.parseInt(config.countdownYear, 10), 2, 14, 0, 0, 0);
  const fields = ['months', 'days', 'hours', 'minutes', 'seconds'];
  const containers = {};
  fields.forEach((field) => {
    containers[field] = $(countdownElement).find(`.countdown-item-${field} .value`);
  });
  let zeroed = false;
  Countdown(
    (timeSpan) => {
      const now = new Date();
      if (now < goal) {
        fields.forEach((field) => {
          containers[field].text(timeSpan[field]);
        });
      } else if (!zeroed) {
        fields.forEach((field) => {
          containers[field].text(0);
        });
        $('.countdown-message')
          .text('Happy International Day of Mathematics!')
          .addClass('show');
        zeroed = true;
      }
    },
    // Start of the event: 14 Mar 2020, 0:00 (local time)
    goal,
    // eslint-disable-next-line no-bitwise
    Countdown.MONTHS | Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS
  );
});

// Event countdown
$('[data-component=event-countdown]').each((i, countdownElement) => {
  if ($(countdownElement).attr('data-goal') === undefined) {
    console.error('Error: event-countdown component with no goal');
    return;
  }
  const goal = parseISO($(countdownElement).attr('data-goal'));
  Countdown(
    (timeSpan) => {
      const now = new Date();
      if (now < goal) {
        const {
          days, hours, minutes, seconds,
        } = timeSpan;
        $(countdownElement).html(`${days} ${days === 1 ? 'day' : 'days'}, ${hours} ${hours === 1 ? 'hour' : 'hours'}, ${minutes} ${minutes === 1 ? 'minute' : 'minutes'} and ${seconds} ${seconds === 1 ? 'second' : 'seconds'}`);
      } else {
        $(countdownElement).html('0 days, 0 hours, 0 minutes and 0 seconds');
      }
    },
    // Start of the event: 14 Mar 2020, 0:00 (local time)
    goal,
    // eslint-disable-next-line no-bitwise
    Countdown.DAYS | Countdown.HOURS | Countdown.MINUTES | Countdown.SECONDS
  );
});
