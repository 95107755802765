/* eslint-disable import/no-extraneous-dependencies */
/* globals google, MarkerClusterer */
import qs from 'qs';
import config from './config';
import Events from './eventData';

// Assigns zoom based on screen width
const zoomBreakpoints = [
  { minWidth: 1200, zoom: 2.1 },
  { minWidth: 992, zoom: 1.8 },
  { minWidth: 768, zoom: 1.4 },
  { minWidth: 0, zoom: 1 },
];

class EventMapGoogle {
  constructor(container, eventSet) {
    this.eventSet = eventSet;
    const { zoom } = zoomBreakpoints.find((item) => item.minWidth <= window.innerWidth);
    this.map = new google.maps.Map(container, {
      zoom,
      minZoom: 1,
      center: { lat: 18, lng: 0 },
    });
    this.infoWindow = new google.maps.InfoWindow();
    this.clusterer = null;
    this.locations = [];
  }

  /**
   * Adds a list of events to the map
   *
   * @param events array
   * @return MarkerClusterer
   */
  addEvents(events) {
    const markers = events.map((event) => this.createMarker(event));
    // eslint-disable-next-line no-unused-vars
    this.clusterer = new MarkerClusterer(this.map, markers, {
      gridSize: 40,
      imagePath: 'vendor/markerclusterer/img/m',
    });
  }

  /**
   * Creates a marker
   *
   * @param event {{
   *   id:number,
   *   lat:number,
   *   lon:number,
   *   name:string,
   *   venue:string,
   *   city:string,
   *   region:string,
   *   country:string
   * }}
   * @return {google.maps.Marker}
   */
  createMarker(event) {
    let lat = Number(event.lat);
    let lng = Number(event.lon);
    const key = `${event.lat},${event.lon}`;
    if (this.locations[key] !== undefined) {
      lat += (Math.random() - 0.5) / 1500;
      lng += (Math.random() - 0.5) / 1500;
    } else {
      this.locations[key] = true;
    }

    // Trim the strings and keep only those that are not empty
    const notEmpty = (arr) => arr
      .map((item) => ((typeof item === 'string') ? item.trim() : item))
      .filter((item) => !!item);

    const title = notEmpty([
      event.name,
      event.venue,
      notEmpty([event.city, event.region, event.country]).join(', '),
    ]).join('\n');

    const htmlContent = title.replace(/\n/g, '<br>')
      + (event.isPublic ? `<br><br><a href="#event-details/${this.eventSet}/${event.id}">More information</a>` : '');

    const publicIcon = {
      url: 'assets/img/map-dot-red.png',
      scaledSize: new google.maps.Size(27, 43),
    };

    const privateIcon = {
      url: 'assets/img/map-dot-fucsia.png',
      scaledSize: new google.maps.Size(27, 43),
    };

    const marker = new google.maps.Marker({
      position: { lat, lng },
      map: this.map,
      title,
      // url: event.url,
      icon: event.isPublic ? publicIcon : privateIcon,
    });

    marker.addListener('click', () => {
      this.infoWindow.setContent(htmlContent);
      this.infoWindow.open(this.map, marker);
    });

    return marker;
  }

  setGeolocation(position) {
    this.map.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
    this.map.setZoom(10);
  }
}

const qsArgs = qs.parse(window.location.search, { ignoreQueryPrefix: true });

window.IMAGINARY.IDM314.InitGoogleMaps = function initGoogleMapsCallback() {
  $('[data-component=event-map-google]').each((i, container) => {
    const eventSet = $(container).attr('data-events-set');
    if (!eventSet || !eventSet.match(/^[a-zA-Z0-9_-]+$/)) {
      return;
    }
    Events.load(eventSet)
      .then((events) => {
        const eventMap = new EventMapGoogle(container, eventSet);
        const min = qsArgs.mapMinEv !== undefined ? Number(qsArgs.mapMinEv) : null;
        const max = qsArgs.mapMaxEv !== undefined ? Number(qsArgs.mapMaxEv) : null;
        const batch = qsArgs.batch !== undefined ? Number(qsArgs.batch) : null;
        eventMap.addEvents(events.filter((event) => (
          (min === null || Number(event.id) >= min)
            && (max === null || Number(event.id) <= max)
            && (batch == null || event.batch === batch)
        )));
        $(container).data('component', eventMap);
      })
      .catch((ex) => {
        console.error('Fetching events.json failed: ', ex);
      });
  });
};

const options = {
  key: config.gmAPIKey,
  callback: 'window.IMAGINARY.IDM314.InitGoogleMaps',
};

if (qsArgs && qsArgs.mapAPIKey !== undefined) {
  options.key = qsArgs.mapAPIKey;
}

const googleMapsScriptElement = document.createElement('script');
const optionsQuery = Object.keys(options)
  .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(options[k])}`)
  .join('&');
const url = `https://maps.googleapis.com/maps/api/js?${optionsQuery}`;
googleMapsScriptElement.setAttribute('src', url);
googleMapsScriptElement.setAttribute('async', '');
googleMapsScriptElement.setAttribute('defer', '');
document.head.appendChild(googleMapsScriptElement);
