/* eslint-disable import/no-extraneous-dependencies */
import qs from 'qs';

if (window.IMAGINARY === undefined) {
  window.IMAGINARY = {};
}
if (window.IMAGINARY.featureFlags === undefined) {
  window.IMAGINARY.featureFlags = [];
}

const qsArgs = qs.parse(window.location.search, { ignoreQueryPrefix: true });

if (qsArgs.feature !== undefined) {
  window.IMAGINARY.featureFlags = qsArgs.feature.split(',');
}

window.IMAGINARY.featureFlags.forEach((flag) => {
  $(`.feature-hide[data-feature-flag=${flag}]`).show();
});
