const config = require('../../config.json');

config.staticFileBasepath = process.env.STATIC_FILE_BASEPATH || '';
config.staticFile = (path) => `${config.staticFileBasepath}/${path}`;

config.gmAPIKey = process.env.GOOGLE_MAPS_API_KEY || '';
config.mapboxAPIKey = process.env.MAPBOX_API_KEY || '';

if (window.IMAGINARY === undefined) {
  window.IMAGINARY = {};
}
if (window.IMAGINARY.IDM314 === undefined) {
  window.IMAGINARY.IDM314 = {};
}

if (window.IMAGINARY.IDM314.config === undefined) {
  window.IMAGINARY.IDM314.config = config;
}

module.exports = config;
