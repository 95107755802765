export default class Modal {
  constructor() {
    this.visible = false;
    this.$bodyContentsContainer = $('<div class="modal-body-contents"></div>');
    this.$titleContainer = $('<div class="modal-title"></div>');
    this.$bodyContainer = $('<div class="modal-body"></div>')
      .append($('<div class="container-fluid"></div>')
        .append($('<div class="row"></div>')
          .append($('<div class="col"></div>')
            .append(this.$bodyContentsContainer))));
    this.$footerContainer = $('<div class="modal-footer"></div>');

    this.$content = $('<div class="modal-content">')
      .append($('<div class="modal-header">')
        .append(this.$titleContainer)
        .append($('<button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close">')))
      .append(this.$bodyContainer);

    this.$element = $('<div class="modal fade" tabindex="-1">')
      .append($('<div class="modal-dialog modal-lg">')
        .append(this.$content));

    this.bsModal = new bootstrap.Modal(this.$element);

    this.$element.on('hidden.bs.modal', () => {
      this.visible = false;
    });

    this.$element.on('shown.bs.modal', () => {
      this.visible = true;
    });
  }

  getElement() {
    return this.$element[0];
  }

  showLoader() {
    this.$titleContainer.empty();
    this.$bodyContentsContainer.html(
      "<div class='lds-ellipsis'><div></div><div></div><div></div></div>"
    );
    this.show('', "<div class='text-center'><div class='lds-ellipsis'><div></div><div></div><div></div></div></div>");
  }

  show(title, content, footer = null) {
    this.$titleContainer.html(title);
    this.$bodyContentsContainer.html(content);

    if (footer) {
      this.$footerContainer.html(footer);
      this.$footerContainer.appendTo(this.$content);
    } else {
      this.$footerContainer.remove();
    }

    if (!this.visible) {
      this.bsModal.show();
    } else {
      this.bsModal.handleUpdate();
    }
  }

  hide() {
    this.bsModal.hide();
  }
}
